<template>
  <v-container>
    <timeseries-analytics
      class="mb-8"
      title="Daily Premium Subscribers"
      _key="DailyPremiumUserCount"
    />
    <v-row no-gutters class="mb-8">
      <v-col cols="6" class="pa-0 pr-4">
        <distribution-user-card
          title="Top Daily Premium Users"
          _key="DailyPremium"
          type="content"
          :params="{ type: 'dailypremium', label_key: 'user_id' }"
          @expand="showListDialog"
          @click="(data) => { viewUserSubscriptions(data.legend) }"
        />
      </v-col>
      <v-col cols="6" class="pa-0 pl-4">
        <distribution-user-card
          title="Top Data Purchasers"
          _key="DataPurchases"
          type="content"
          :params="{ type: 'zerodata_purchases', label_key: 'user_id', count_key: 'total_amount' }"
          @expand="showListDialog"
          @click="(data) => { viewUserSubscriptions(data.legend) }"
        />
      </v-col>
    </v-row>
    <div>
      <loader
        v-if="isLoading"
        message="Loading ...."
      />
      <div v-else>
        <s-text
          weight="bold"
          size="md-m"
          class="mb-4"
        >
          Active Subscribers
        </s-text>
        <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
          <v-col class="d-flex flex-column py-0" cols="5">
            <s-text weight="medium" color="gray" size="sm">
              User
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              Expiration Date
            </s-text>
          </v-col>
          <v-col class="d-flex flex-column py-0" cols="3">
            <s-text weight="medium" color="gray" size="sm">
              Activation Date
            </s-text>
          </v-col>
        </v-row>
        <v-row
          v-for="subscription in subscriptions"
          :key="subscription.id"
          class="pa-0 ma-0 mb-3 app-card app-card-content"
          @click="viewUserSubscriptions(subscription.user_id)"
        >
          <v-col class="d-flex flex-column" cols="5">
            <div class="d-flex align-center">
            <v-avatar class="mr-4" size="40">
              <v-img
                aspect-ratio="1"
                cover
                :lazy-src="require(`@/assets/avatars/user.svg`)"
                :src="`${baseURL}/v1/users/${subscription.user_id}/picture`"
              />
            </v-avatar>
            <div class="align-center name-holder" style="width: 100%;">
              <s-text
                weight="medium"
                color="primary"
              >
                {{ subscription.bio.name }}
              </s-text>
              {{ subscription?.username }}
            </div>
          </div>
          </v-col>
          <v-col class="d-flex align-center" cols="3">
            <div>
              {{ subscription.Config.Premium.ExpirationDate ? getHumanReadableDateShort(subscription.Config.Premium.ExpirationDate) : "-" }}
            </div>
          </v-col>
          <v-col class="d-flex align-center" cols="3">
            <div>
              {{ subscription.Config.Premium.LastActivationDate ? getHumanReadableDateShort(subscription.Config.Premium.LastActivationDate) : "-" }}
            </div>
          </v-col>
          <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
            <s-icon name="arrow-circle-right" class="mr-2" />
          </v-col>
        </v-row>
      </div>
    </div>
    <s-dialog
      v-model="showDialog"
      persistent
      isModal
      :title="modalTitle"
      @close="showModal = false; listModalFn = () => {};"
      :close-default="modalCloseDafaultSettings"
    >
      <user-data-list
        v-if="showModal"
        :getter="getter"
        @click="listModalFn"
      />
    </s-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import TimeseriesAnalytics from '@/components/cards/TimeseriesAnalytics'
import DistributionUserCard from '@/components/cards/distribution-analytics/DistributionUserCard'
import UserDataList from '@/components/cards/lists/UserDataList'
import { getHumanReadableDateShort } from '@/utils/date'
export default {
  name: 'Transactions',
  components: {
    loader: Loader,
    'timeseries-analytics': TimeseriesAnalytics,
    'distribution-user-card': DistributionUserCard,
    'user-data-list': UserDataList,
  },
  props: {
    txnId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      subscriptions: 'analytic/subscribedUsers',
    }),
    baseURL () {
      return process.env.VUE_APP_API_DOMAIN
    },
    showDialog () {
      return !!this.showModal
    },
    modalTitle () {
      return this.title ? this.title : ''
    },
    modalCloseDafaultSettings () {
      return !this.showModal
    },
  },
  data () {
    return {
      showModal: this.$route.meta.showModal,
      listModalFn: () => {},
      title: '',
      getter: null,
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCurrencyFormatted (unit) {
      return (unit / 100).toFixed(2)
    },
    getSubscriptions () {
      this.isLoading = true
      this.$store
        .dispatch('analytic/getSubscribedUsers')
        .finally(() => {
          this.isLoading = false
        })
    },
    viewUserSubscriptions (id) {
      this.$router.push({ name: 'appSubscriptions', params: { id: id } })
    },
    showListDialog ({ title, getter }) {
      this.title = title
      this.getter = getter
      this.showModal = true

      if (title === 'Daily Premium Users') this.listModalFn = this.viewUserSubscriptions
    },
  },
  watch: {
    subscriptions: {
      handler () {
        if (this.subscriptions) return
        this.getSubscriptions()
      },
      immediate: true,
    },
  },
  destroyed () {
    this.$store.dispatch('analytic/clear')
  },
}
</script>
